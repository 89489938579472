.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.lightbox__content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  min-width: calc(100% - 20%);
  max-width: calc(100% - 20%);
  max-height: calc(100% - 20%);
  overflow: auto;
}

.lightbox__close {
  position: absolute;
  right: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.lightbox__close:hover {
  opacity: 1;
}
.lightbox__close:before,
.lightbox__close:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 4px;
  background-color: #ffffff;

}
.lightbox__close:before {
  transform: rotate(45deg);
}
.lightbox__close:after {
  transform: rotate(-45deg);
}

@media (min-width: 600px) {
  .lightbox {
    top: 3rem;
  }
}
